import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

interface FormData {
    name: string;
    email: string;
}

interface DialogWithFormProps {
    onClose: (data: FormData | null) => void;
}

const AnonymousDialog: React.FC<DialogWithFormProps> = ({ onClose }) => {
    const [formData, setFormData] = useState<FormData>({ name: '', email: '' });
    const [errors, setErrors] = useState<Partial<FormData>>({});

    const validate = () => {
        let valid = true;
        const newErrors = { name: '', email: '' };

        // Validate name
        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
            valid = false;
        }

        // Validate email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
            valid = false;
        } else if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Enter a valid email';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleClose = (submit: boolean): void => {
        if (submit && validate()) {
            onClose(formData); // Return formData to parent on submit
        } else if (!submit) {
            onClose(null); // No data returned if canceling
        }
    };

    return (
        <Dialog open={true} onClose={() => handleClose(false)}>
            <DialogTitle>Enter Your Details</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={formData.name}
                    onChange={handleInputChange}
                    error={!!errors.name}
                    helperText={errors.name}
                />
                <TextField
                    margin="dense"
                    name="email"
                    label="Email"
                    type="email"
                    fullWidth
                    variant="outlined"
                    value={formData.email}
                    onChange={handleInputChange}
                    error={!!errors.email}
                    helperText={errors.email}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(true)}>Submit</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AnonymousDialog;
